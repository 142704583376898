import axios from "@/utils/request";

/*********************** 首页 ******************/
// 获取app列表
export function getHomeApps() {
  return axios.post(`/bpsfafmgeg/wloOmfnkc8dk`);
}
// 广告点击统计
export function advClick(data) {
  let config = {
    headers: {
      "log-id": data.id,
      "log-type": "app",
    },
  };
  return axios.get(`/bpsfafmgeg/dHH9h0Kp1074`, config);
}
/**
 * 截取URL参数
 * @param name 需要截取url的参数
 * @returns
 */
function getUrlParam(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
  var r = window.location.search.substr(1).match(reg); //匹配目标参数
  if (r != null) return unescape(r[2]);
  return null; //返回参数值
}
// 记录
export function visitRecord() {
  let config = {
    headers: {
      reqLog: "reqLog",
    },
  };
  if (getUrlParam("ch_code")) {
    config.headers.channel = getUrlParam("ch_code");
  }
  return axios.get(`/bpsfafmgeg/lo0mCfBtosTF`, config);
}
