import CryptoJS from "crypto-js";
const sha1 = require("js-sha1");
var ts = Math.round(new Date().getTime() / 1000);
var ac = sha1(ts + "18b^1Ya1e5u*C19f");
const key = CryptoJS.enc.Utf8.parse("LAd0pEr6T^TL(1la"); //十六位十六进制数作为密钥
const iv = CryptoJS.enc.Utf8.parse(ac.substr(0, 16)); //十六位十六进制数作为密钥偏移量

function ACEL(acEL__) {
  if (acEL__) {
    ac = acEL__;
  }
  return ac;
}

export { ACEL };
