import CryptoJS from "crypto-js";

//新增：登陆uuid解密方法
// const loginKey = CryptoJS.enc.Utf8.parse('xB$4ArkG4^I5nHI='); //十六进制数作为密钥
// const loginiIv = CryptoJS.enc.Utf8.parse('c75853a65b092ed6'); //十六进制数作为密钥偏移量
const loginKey = CryptoJS.enc.Utf8.parse("xB$4ArkG4^I5nHI="); //十六进制数作为密钥
const loginiIv = CryptoJS.enc.Utf8.parse("c75853a65b092ed6"); //十六进制数作为密钥偏移量

function LoginDecrypt(word, acEl) {
  let decrypt;
  if (acEl) {
    decrypt = CryptoJS.AES.decrypt(word, loginKey, {
      iv: loginiIv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
  } else {
    decrypt = CryptoJS.AES.decrypt(word, loginKey, {
      iv: loginiIv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
  }
  let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
  return decryptedStr.toString();
}

export { LoginDecrypt };
