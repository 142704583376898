import Vue from "vue";
import App from "./App.vue";
import router from "./router"; //路由
import store from "./store"; //引入store
import "@/assets/css/reset.css"; //初始化css
import VueTouch from "vue-touch"; //监听手势
import "./permission";
import { Lazyload } from "vant";
import "lib-flexible";
// import "@/icons";
import { Api } from "@/utils";
import VueClipboard from "vue-clipboard2";
// import * as filters from "./assets/js/filters";

import { Tab, Tabs, Overlay, Swipe, SwipeItem } from "vant";

Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Overlay);
Vue.use(Swipe);
Vue.use(SwipeItem);

window.Hls = require("hls.js");
Vue.config.productionTip = false;
Vue.prototype.$Api = Api;
Vue.use(VueClipboard);
// Object.keys(filters).forEach((key) => {
//   Vue.filter(key, filters[key]); //插入过滤器名和对应方法
// });

Vue.use(Lazyload, {
  lazyComponent: true,
});
Vue.use(VueTouch, { name: "v-touch" });
new Vue({
  router,
  store,
  render: (h) => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this;
  },
}).$mount("#app");
