<template>
  <div class="home">
    <div class="splash" v-if="startApp.length > 0 && showStartImg">
      <div class="imgbox" @click="jump(startApp[0].url)">
        <ImgDecypt
          class="startappImg"
          :src="startApp[0].icon"
          :isDecypt="true"
        ></ImgDecypt>
      </div>
      <div class="countdown">
        <span v-if="countdown !== 0">{{ countdown }}</span>
        <div v-else @click="closeSplash">关闭</div>
      </div>
    </div>
    <div v-else class="main" id="main">
      <template v-if="!loading">
        <div class="headerBox">
          <div class="logo"></div>
          <div class="more" @click="jumpMore('https://qcbfr.com')"></div>
        </div>
        <div class="hotAppBox">
          <div class="hotTitle"></div>
          <!-- 热门应用 -->
          <div class="hotTopAppBox" v-if="hotApp.length > 0">
            <div
              class="appBox"
              v-for="(item, index) in hotApp"
              :key="item.id"
              @click="jump(item)"
            >
              <template v-if="index < 3">
                <div class="appIcon">
                  <ImgDecypt
                    :src="item.icon"
                    :isDecypt="true"
                    :key="item.id"
                  ></ImgDecypt>
                </div>
                <div class="title">{{ item.name }}</div>
                <div class="starBottom" v-if="index > 0"></div>
              </template>
            </div>
          </div>
        </div>
        <div class="appList" v-if="ordinaryApp.length > 0">
          <div
            class="appBox"
            v-for="item in ordinaryApp"
            :key="item.id"
            @click="jump(item)"
          >
            <div class="appIcon">
              <ImgDecypt :src="item.icon" :isDecypt="true"></ImgDecypt>
            </div>
            <div class="title">{{ item.name }}</div>
            <div class="starBottom"></div>
          </div>
        </div>
        <div class="appList" v-if="recommendApp.length > 0">
          <div
            class="appBox"
            v-for="item in recommendApp"
            :key="item.id"
            @click="jumpDownloadApp(item)"
          >
            <div class="appIcon">
              <ImgDecypt :src="item.icon" :isDecypt="true"></ImgDecypt>
            </div>
            <div class="title">{{ item.name }}</div>
            <div class="starBottom"></div>
          </div>
        </div>
        <van-swipe
          class="my-swipe"
          :autoplay="3000"
          indicator-color="white"
          :show-indicators="false"
          v-if="bannerApp.length > 0"
        >
          <van-swipe-item
            v-for="item in bannerApp"
            :key="item.id"
            @click="jump(item)"
          >
            <ImgDecypt
              class="bannerImg"
              :src="item.icon"
              :isDecypt="true"
              objFit="fill"
            ></ImgDecypt>
          </van-swipe-item>
        </van-swipe>
        <!-- 可能喜欢 -->
        <div class="guessBox">
          <div class="guessTitle"></div>
          <div class="guessItemBox">
            <div
              class="guessItem"
              @click="jump(item)"
              v-for="item in guessApp"
              :key="item.id"
            >
              <div class="name">{{ item.name }}</div>
              <span class="right_arrow"></span>
            </div>
          </div>
        </div>
        <!-- 联系方式 -->
        <div class="connectBox">
          <div class="starTop"></div>
          <div
            class="contactDetails"
            @click="jumpMore('https://t.me/xiongx777')"
          >
            <div class="contactTitle">商务合作</div>
            <div class="contactItem">唯一飞机号： @xiongx777</div>
          </div>
          <div class="backTopBtn" @click="backTop"></div>
          <div class="starBottom"></div>
        </div>
      </template>
      <Loading v-else />
    </div>
    <van-overlay :show="showPop">
      <div v-if="popupNotifier.length > 0" class="advertiseBox">
        <div
          class="contentImg"
          v-for="item in popupNotifier"
          :key="item.id"
          @click="jump(item)"
        >
          <ImgDecypt
            :src="item.icon"
            :isDecypt="true"
            :key="item.id"
          ></ImgDecypt>
        </div>
        <div class="imgBtn" @click="closeImg"></div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { getHomeApps, visitRecord } from "@/api/home";
import { jumpAdvs } from "@/utils/getConfig";
import { LoginDecrypt } from "@/utils/aes";
import { ACEL } from "@/utils/acEl";
import axios from "axios";
import Loading from "@/components/Loading/index.vue";

export default {
  name: "home",
  components: {
    ImgDecypt: () => import("@/components/ImgDecypt"),
    Loading,
  },
  data() {
    return {
      hotApp: [],
      startApp: [],
      guessApp: [],
      ordinaryApp: [],
      popupNotifier: [],
      bannerApp: [],
      recommendApp: [],
      countdown: 5, //广告倒计时
      showStartImg: true,
      timer: null,
      loading: true,
      showPop: false,
    };
  },
  created() {
    this.getApps();
  },
  methods: {
    async getApps() {
      let res = await this.$Api(getHomeApps);
      this.loading = false;
      if (res && res.code == 200) {
        this.hotApp = res.data.hotApp || [];
        this.bannerApp = res.data.bannerApp || [];
        this.startApp = res.data.startApp || [];
        this.guessApp = res.data.guessApp || [];
        this.ordinaryApp = res.data.ordinaryApp || [];
        this.popupNotifier = res.data.popupNotifier || [];
        this.recommendApp = res.data.recommendApp || [];
        if (this.$route.query.imei || this.$route.query.verify) {
          this.sgin();
        }
        let firstTime = sessionStorage.getItem("firstTime");
        if (firstTime != "1") {
          sessionStorage.setItem("firstTime", "1");
          if (this.popupNotifier.length > 0) {
            this.showPop = true;
          }
          this.showStartImg = true;
          this.timer = setInterval(() => {
            if (this.countdown <= 0) {
              clearInterval(this.timer);
            } else {
              this.countdown--;
            }
          }, 1000);
        } else {
          this.showStartImg = false;
        }
        this.hasVisit();
        this.record();
      }
    },
    jump(data) {
      jumpAdvs(data);
    },
    // 跳转更多
    jumpMore(url) {
      window.open(url);
    },
    backTop() {
      document.getElementById("main").scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    closeImg() {
      this.popupNotifier.pop();
      if (this.popupNotifier.length == 0) {
        this.showPop = false;
      }
    },
    closeSplash() {
      this.showStartImg = false;
      let firstTime = sessionStorage.getItem("firstTime");
      if (this.popupNotifier.length > 0 && firstTime != "1") {
        sessionStorage.setItem("firstTime", "1");
        this.showPop = true;
      }
    },
    async record() {
      let isRecord = sessionStorage.getItem("isRecord");
      if (!isRecord) {
        await this.$Api(visitRecord);
        sessionStorage.setItem("isRecord", "1");
      }
    },
    async sgin() {
      let imei, platform, deviceType;
      if (this.$route.query.imei) {
        imei = this.$route.query.imei;
        platform = "android";
        deviceType = "android";
      } else if (this.$route.query.verify) {
        let acel = ACEL();
        let verify = window.decodeURIComponent(this.$route.query.verify);
        imei = LoginDecrypt(verify, acel);
        platform = "h5";
        deviceType = "iphone";
      }
      if (this.$route.query && imei) {
        let req = {
          imei: imei,
          platform: platform,
          device_type: deviceType,
          channel_code: this.$route.query.ch_code,
          inviteCode: this.$route.query.inviteCode,
        };
        await axios.post("https://xfl-app.jjffoo.cn/api/guest/sign", req);
      }
    },
    // 判断设备类型
    device(context) {
      const userAgent = process.server
        ? context.req.headers["user-agent"]
        : navigator.userAgent;
      return (function () {
        const ua = userAgent;
        const isUc = /(?:UCBrowser)/.test(ua);
        const isWindowsPhone = /(?:Windows Phone)/.test(ua) && !isUc;
        const isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone;
        const isAndroid = /(?:Android)/.test(ua) || isUc;
        const isFireFox = /(?:Firefox)/.test(ua);
        const isOPPO = /(?:OPPO)/.test(ua);
        const isTablet =
          /(?:iPad|PlayBook)/.test(ua) ||
          (isAndroid && !/(?:Mobile)/.test(ua)) ||
          (isFireFox && /(?:Tablet)/.test(ua));
        const isPhone = /(?:iPhone)/.test(ua) && !isTablet;
        const isWx = /MicroMessenger/.test(ua);
        const isPc = !isPhone && !isAndroid && !isSymbian;
        return {
          userAgent,
          isOPPO,
          isTablet,
          isPhone,
          isAndroid,
          isPc,
          isWx,
        };
      })();
    },
    // 页面访问统计
    async hasVisit() {
      // 获取地址栏中是否存在dc
      const dcEl = this.getQueryString("dc");
      const codeEl = this.getQueryString("code");
      const verify = this.getQueryString("verify");
      var platformEl = this.device(null).isPhone ? "ios" : "android";
      const paramData = {
        app_id: "yanse",
        platform: platformEl,
        channel_code: dcEl,
        invite_code: codeEl,
        domain: window.location.href,
        currentDate: new Date().getTime(),
      };
      if (!localStorage.getItem("hasVisit") && dcEl && !verify) {
        this.visitApi(paramData);
      }
    },
    /**
     *  截取url参数
     * @param {*} name 截取url参数名称
     * @returns
     */
    getQueryString(name) {
      const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      const r = window.location.search.substring(1).match(reg);
      if (r != null) {
        return decodeURIComponent(r[2]);
      }
      return null;
    },
    //访问记录
    async visitApi(paramData) {
      const res = await axios({
        url: `https://statapi.htycpc.com/api/link/visitlog`,
        method: "post",
        data: paramData,
      });
      if (res.data.code == 200) {
        localStorage.setItem("hasVisit", JSON.stringify(paramData));
      }
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .van-overlay {
  z-index: 2000;
}
.home {
  height: 100%;
  width: 100%;
  .splash {
    position: relative;
    width: 100%;
    height: 100vh;
    background: #000;
    z-index: 20001;
    .imgbox {
      width: 100%;
      height: 100%;
      .startappImg {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .countdown {
      position: absolute;
      top: 15px;
      right: 13px;
      z-index: 999;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 12px;
      box-shadow: rgba(0, 0, 0, 0.3) 1px 1px 1px;
      cursor: pointer;
    }
  }
  .main {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    background: #00000090 url("./../../assets/png/bg.webp") no-repeat;
    background-size: 100% 1344px;
    background-blend-mode: color-dodge;
    .headerBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 30px 20px 0;
      .logo {
        height: 89px;
        width: 122px;
        background: url("./../../assets/png/logo.png") no-repeat;
        background-size: 100% 100%;
      }
      .more {
        height: 35px;
        width: 150px;
        background: url("./../../assets/png/more.gif") no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
      }
    }
    .hotAppBox {
      .hotTitle {
        height: 37px;
        width: 119px;
        margin: 30px auto 25px;
        background: url("./../../assets/png/hotTitle.png") no-repeat;
        background-size: contain;
      }
      .hotTopAppBox {
        margin: 0 18px 13px;
        display: flex;
        justify-content: space-evenly;
        .appBox {
          height: 102px;
          width: 102px;
          background: url("./../../assets/png/appBox.png") no-repeat;
          background-size: contain;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          .appIcon {
            height: 69px;
            width: 69px;
            position: absolute;
            top: 7px;
          }
          .title {
            font-size: 12px;
            font-weight: 500;
            color: white;
            position: absolute;
            z-index: 2;
            bottom: 5px;
          }
          .starBottom {
            height: 18px;
            width: 18px;
            background: url("./../../assets/png/star2.png") no-repeat;
            background-size: 100% 100%;
            position: absolute;
            z-index: 2;
            left: -10px;
            bottom: -8px;
          }
        }
      }
    }
    .appList {
      padding: 0 18px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-column-gap: 8px;
      grid-row-gap: 2px;
      .appBox {
        height: 81px;
        width: 73px;
        background: url("./../../assets/png/appBoxSmall.png") no-repeat;
        background-size: contain;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 5px;
        cursor: pointer;
        .title {
          font-size: 11px;
          font-weight: 600;
          color: white;
          position: absolute;
          z-index: 2;
          bottom: 5px;
        }
        .appIcon {
          height: 53px;
          width: 53px;
          position: absolute;
          top: 6px;
        }
        .starBottom {
          height: 18px;
          width: 18px;
          background: url("./../../assets/png/star2.png") no-repeat;
          background-size: 100% 100%;
          position: absolute;
          z-index: 2;
          left: -10px;
          bottom: -5px;
        }
      }
    }
    .my-swipe {
      margin: 30px 21px 7px;
      border-radius: 3px;
    }
    .my-swipe .van-swipe-item {
      color: #fff;
      font-size: 10px;
      line-height: 75px;
      text-align: center;
      height: 102px;
    }
    .guessBox {
      .guessTitle {
        background: url("./../../assets/png/guess.png") no-repeat;
        background-size: 100% 100%;
        height: 37px;
        width: 123px;
        margin: 26px auto 22px auto;
      }
      .guessItemBox {
        padding: 0 18px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 8px;
        grid-row-gap: 0px;
        .guessItem {
          height: 31.5px;
          width: 91px;
          border-radius: 3px;
          border: 1px solid rgba(255, 255, 255, 0.4);
          background: linear-gradient(
            to right,
            rgba(255, 0, 119, 0.4),
            rgba(0, 236, 255, 0.4)
          );
          color: white;
          font-size: 12px;
          margin-bottom: 11px;
          position: relative;
          line-height: 31.5px;
          padding-left: 10px;
          cursor: pointer;
          .right_arrow {
            width: 15px;
            height: 15px;
            display: inline-block;
            background: url("./../../assets/png/rightArrow.png") no-repeat;
            background-size: 100% 100%;
            position: absolute;
            right: 10px;
            top: 8px;
          }
        }
      }
    }
    .connectBox {
      margin: 40px 18px 37px;
      display: flex;
      justify-content: center;
      position: relative;
      .contactDetails {
        height: 29px;
        width: 235.5px;
        background-color: white;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .contactTitle {
          height: 21px;
          width: 60px;
          background-color: #ea46f1;
          border-radius: 3px;
          text-align: center;
          line-height: 21px;
          font-size: 13px;
          color: white;
          font-weight: 400;
        }
        .contactItem {
          font-size: 13px;
          margin-left: 5px;
          color: #555555;
        }
      }
      .backTopBtn {
        width: 37px;
        height: 37px;
        background: url("./../../assets/png/backTop.png") no-repeat;
        background-size: contain;
        position: absolute;
        right: 0;
        top: 20px;
        cursor: pointer;
      }
      .starTop {
        height: 22.5px;
        width: 22.5px;
        background: url("./../../assets/png/star3.png") no-repeat;
        background-size: 100% 100%;
        position: absolute;
        z-index: 2;
        left: 30px;
        top: -15px;
      }
      .starBottom {
        height: 15.5px;
        width: 15.5px;
        background: url("./../../assets/png/star2.png") no-repeat;
        background-size: 100% 100%;
        position: absolute;
        z-index: 2;
        right: 40px;
        bottom: -10px;
      }
    }
  }
}
.advertiseBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 520px;
  width: 300px;
  .contentImg {
    width: 300px;
    height: 400px;
    position: absolute;
    /deep/ img {
      object-fit: fill !important;
    }
  }
  .imgBtn {
    width: 40px;
    height: 40px;
    background: url("./../../assets/png/close_red.png") 100%/100% no-repeat;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, 0);
    cursor: pointer;
  }
}
</style>
